import React, { useState, useEffect } from "react"
import styled from "@emotion/styled"
import Lottie from "react-lottie-wrapper"
import * as animationData from "../../images/restalaite-lottie.json"

const StyledDiv = styled.div`
  display: none;
  width: 100%;
  height: auto;
  text-align: center;
  margin-bottom: 2em;
  > div {
    margin: 0 auto;
  }
  svg {
    transform: none !important;
  }
  @media ${({ theme }) => theme.breakpoint.tablet} {
    display: block;
  }
`

const LogoAnimation = () => {
  const [isStopped, setIsStopped] = useState(true)

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsStopped(false)
    }, 1000)
    return () => clearTimeout(timer)
  }, [])

  const defaultOptions = {
    loop: true,
    autoplay: false,
    animationData: animationData.default,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }

  useEffect(() => {})

  return (
    <StyledDiv className="restalaite-animation">
      <Lottie
        isClickToPauseDisabled
        options={defaultOptions}
        width="65%"
        height="100%"
        isStopped={isStopped}
      />
    </StyledDiv>
  )
}

export default LogoAnimation
