import React, { useContext, useEffect, useState } from "react"
import { shape } from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "@emotion/styled"
import Fade from "react-reveal/Fade"
import BlockWrapper from "../atoms/block-wrapper"
import LogoMobile from "../atoms/logo-mobile"
import Emblem from "../atoms/emblem"
import UIContext from "./ui-context"
import LogoAnimation from "./logo-animation"
import { useWindowDimensions } from "../../helpers"

const propTypes = {
  data: shape().isRequired,
  randomHeroImage: shape().isRequired,
}

const HeroContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-left: -15px;
  margin-right: -15px;
  margin-top: -50px;
  @media ${({ theme }) => theme.breakpoint.tablet} {
    margin-left: -30px;
    margin-right: -30px;
  }
  @media ${({ theme }) => theme.breakpoint.laptop} {
    margin-left: -45px;
    margin-right: -45px;
  }
  @media ${({ theme }) => theme.breakpoint.navCollapse} {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
  }
  .react-reveal {
    width: 100%;
  }
  .gatsby-image-wrapper {
    /* width: ${({ size }) => size.width}px!important; */
    height: 100vh!important;
    @media ${({ theme }) => theme.breakpoint.navCollapse} {
      height: ${({ size }) => size.height}px!important;
    }
  }
`

const Logos = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  z-index: 1;
`
const LogoMobileContainer = styled.div`
  width: 100%;
  svg {
    width: 55%;
    height: auto;
  }
  @media ${({ theme }) => theme.breakpoint.tablet} {
    display: none;
  }
`
const EmblemContainer = styled.div`
  width: 100%;
  svg {
    width: 35%;
    height: auto;
  }
  @media ${({ theme }) => theme.breakpoint.tablet} {
    display: block;
    svg {
      width: 18%;
    }
  }
`

const MainPageHero = ({ randomHeroImage, data }) => {
  const { height } = useWindowDimensions()
  const { headerSizes, footerSizes } = useContext(UIContext)
  const [mainHeaderSize, setMainHeaderSize] = useState({
    width: "100vw",
    height: "87vh",
  })

  useEffect(() => {
    const heroHeight = height - headerSizes.height - footerSizes.height
    setMainHeaderSize({ width: "100%", height: heroHeight })
  }, [headerSizes, footerSizes, height])
  return (
    <StaticQuery
      query={graphql`
        query allContentfulMainPageHero {
          allContentfulMainPageHero(filter: { node_locale: { eq: "fi" } }) {
            edges {
              node {
                id
                heroImages {
                  image {
                    contentful_id
                    fluid(maxWidth: 1920, maxHeight: 1103) {
                      ...GatsbyContentfulFluid
                    }
                  }
                }
                emblem {
                  svg {
                    content
                  }
                  file {
                    contentType
                    url
                  }
                }
                logo {
                  svg {
                    content
                  }
                  file {
                    url
                  }
                }
                logoMobile {
                  svg {
                    content
                  }
                  file {
                    contentType
                    url
                  }
                }
              }
            }
          }
        }
      `}
      render={imageData => {
        const images = imageData.allContentfulMainPageHero.edges.find(i => {
          return i.node.id === data.id
        })
        if (!images) {
          return null
        }
        const { logoMobile, emblem } = images.node
        return (
          <BlockWrapper>
            <HeroContainer size={mainHeaderSize}>
              <Logos>
                <Fade
                  ssrFadeout
                  ssrReveal
                  bottom
                  distance="3em"
                  duration={1000}
                  delay={700}
                >
                  <LogoAnimation />
                  {/* <StyledDiv
                    color="#fff"
                    dangerouslySetInnerHTML={{ __html: logo.svg.content }}
                  /> */}
                  <LogoMobileContainer>
                    <LogoMobile data={logoMobile} color="#ffffff" />
                  </LogoMobileContainer>
                </Fade>
                <Fade
                  ssrFadeout
                  ssrReveal
                  bottom
                  distance="3em"
                  duration={1000}
                  delay={1000}
                >
                  <EmblemContainer>
                    <Emblem data={emblem} color="#ffffff" />
                  </EmblemContainer>
                </Fade>
                {/* <Logo src={logo.file.url} /> */}
              </Logos>
              <Fade ssrReveal ssrFadeout duration={1000} delay={300}>
                <Img
                  style={{
                    width: "100%",
                    height: "87vh",
                    paddingBottom: "2vw",
                  }}
                  fluid={randomHeroImage.fluid}
                  alt={randomHeroImage.title}
                />
              </Fade>
            </HeroContainer>
          </BlockWrapper>
        )
      }}
    />
  )
}

MainPageHero.propTypes = propTypes

export default MainPageHero
