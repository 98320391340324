import React from "react"
import { shape, string } from "prop-types"
import styled from "@emotion/styled"

const propTypes = {
  data: shape().isRequired,
  color: string,
}

const defaultProps = {
  color: "",
}

const StyledDiv = styled.div`
  width: 100%;
  height: auto;
  text-align: center;
  svg {
    fill: ${({ color }) => color || "#000"};
  }
`

const Emblem = ({ data, color }) => {
  const { file, svg } = data
  if (file.contentType === "image/svg+xml") {
    if (svg && svg.content) {
      return (
        <StyledDiv
          color={color}
          dangerouslySetInnerHTML={{ __html: svg.content }}
        />
      )
    }
  }
  return null
}

Emblem.propTypes = propTypes

Emblem.defaultProps = defaultProps

export default Emblem
