import React from "react"
import { shape } from "prop-types"
import { graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"
import styled from "@emotion/styled"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContentBlocks from "../components/content-blocks"
import MainPageHero from "../components/molecules/main-page-hero"

const propTypes = {
  data: shape().isRequired,
  intl: shape().isRequired,
}

const MainPageHeroLink = styled.a`
  cursor: pointer;
`
const Home = ({ data, intl, location }) => {
  const { locale } = intl
  const hero = data.contentfulMainPageHero
  const contentBlocks = data.allContentfulPage.edges
    .filter((e) => e.node.node_locale === locale)
    .map((c) => c.node.contentBlocks)[0]
  const slugs = {
    en: "",
    fi: "",
  }

  const { heroImages } = data.mainPageHeroImages

  const randomHeroImage =
    heroImages[Math.floor(Math.random() * heroImages.length)].image

  const scrollToContent = () => {
    if (window) {
      const element = document.getElementById("index_content")
      const windowWidth = window.innerWidth
      const top =
        windowWidth > 1279 ? element.offsetTop + 100 : element.offsetTop

      window.scrollTo({
        behavior: element ? "smooth" : "auto",
        top: element ? top : 0,
      })
    }
  }

  return (
    <Layout slugs={slugs} currentPath={`/${locale}`}>
      <SEO
        title={intl.formatMessage({ id: "home.title" })}
        location={location.pathname}
      />
      <MainPageHeroLink onClick={() => scrollToContent()}>
        <MainPageHero randomHeroImage={randomHeroImage} data={hero} />
      </MainPageHeroLink>
      <div id="index_content">
        <ContentBlocks blocks={contentBlocks} />
      </div>
    </Layout>
  )
}

Home.propTypes = propTypes

export default injectIntl(Home)

export const pageQuery = graphql`
  query {
    mainPageHeroImages: contentfulMainPageHero(
      contentful_id: { eq: "7DAVOb5Off1qA5eG8ERl5S" }
      node_locale: { eq: "fi" }
    ) {
      heroImages {
        image {
          contentful_id
          fluid(maxWidth: 1920, maxHeight: 1103) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
    contentfulMainPageHero(contentful_id: { eq: "7DAVOb5Off1qA5eG8ERl5S" }) {
      id
      logo {
        id
      }
      logoMobile {
        id
      }
      emblem {
        id
      }
      image {
        id
      }
    }
    allContentfulPage(filter: { slug: { in: ["yritys", "company"] } }) {
      edges {
        node {
          title
          node_locale
          colourTheme
          contentBlocks {
            __typename
            ... on ContentfulHeroImage {
              id
              image {
                contentful_id
                title
              }
            }
            ... on ContentfulPageHeader {
              title
              id
              childContentfulPageHeaderColumn2RichTextNode {
                json
              }
              childContentfulPageHeaderColumn3RichTextNode {
                json
              }
            }
            ... on ContentfulThreeColumnBlock {
              title
              id
              columns {
                id
                title
                image {
                  contentful_id
                  title
                }
                body {
                  json
                }
                button {
                  title
                  slug
                }
              }
            }
            ... on ContentfulContactList {
              title
              id
              columns {
                id
                title
                image {
                  contentful_id
                  title
                }
                body {
                  json
                }
              }
            }
          }
        }
      }
    }
  }
`
